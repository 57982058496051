import styled from 'styled-components'
import { white, grayscale, orange, gray } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type imageProps = {
  width?: number;
}

export const Container = styled.div`
  min-height: calc(100vh - 96px);
  border-radius: 16px;
  background: ${white};
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    min-height: auto;
  }
  
  .form {
    width: 100%;
    
    @media ${device.tablet} {
      margin: 0 auto;
      max-width: 664px;
      padding: 0;
    }
    @media ${device.desktopLG} {
      max-width: 408px;
    }
    @media ${device.desktopLG} {
      max-width: 500px;
    }
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 96px);
  }

  h1, h2, h3 {
    font-family: Sora;
  }

  input {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    &::placeholder {
      font-family: 'Sora';
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: ${grayscale[300]};
    }
  }

  label {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
    color: ${grayscale[500]};
  }

  .back {
    display: initial;
    @media (min-width: 1601px) {
      display: block;
    }
  }

  button[type="submit"] {
    height: 48px;
    width: 100%;
    max-width: 100%;

    &:disabled {
      background: ${grayscale['200']};
      color: ${grayscale['300']} !important;
    }
  }

  .form-label-check {
    &:before {
      top: 34px;
      @media ${device.tablet} {
        top: 8px;
      }
      @media ${device.desktopLG} {
        top: 17px;
      }
      @media ${device.desktopXXXL} {
        top: 2px;
      }
    }
  }

  .mt-mobile {
    margin-top: 14px;
  }

  .form-input-check {
    &:checked ~ label {   
    &::after {
      top: 37px;  
        @media ${device.tablet} {
          top: 11px;
        } 
        @media ${device.desktopLG} {
          top: 20px;
        }
        @media ${device.desktopXL} {
          top: 20px;
        }
        @media ${device.desktopXXXL} {
          top: 5px;
        }  
      }
    }
  }

  .input-error {
    label, p {
      color: #F56A50;
    }
    input {
      background-color: #FEF0ED;
    }
  }

  #qrCodeContainer canvas { 
    height: 200px;
    width: 200px;
  }
`
export const Image = styled.img`
  width: ${(props: imageProps) => props.width ? `${props.width}px` : '104px'};
  height: auto;
`
export const Checkbox = styled.div`
  margin: 20px 0;
  input {
    display: block;
  }
  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }
    &::before {
      border: 2px solid ${grayscale[300]};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
    }
    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }
  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;
    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
    &::before {
      background: ${orange.extra};
      border: 1px solid ${orange.extra};
    }
    &::after {
      left: 4px;
      top: 42%;
      width: 6px;
      height: 10px;
      border: solid ${white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translateY(-50%);
      }
    }
  }
`
